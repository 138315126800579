var swiper__content = new Swiper('.swiper__content', {
    speed: 400,
    spaceBetween: 200,
    allowTouchMove: false,
    pagination: {
        el: '.l-headerHome .swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
    thumbs: {
        swiper: swiper__figure
    },
    autoplay: {
        delay: 5000,
    },
    breakpoints: {
        // when window width is >= 320px
        810: {
            allowTouchMove: true,
        }
    }
});

var swiper__figure = new Swiper('.swiper__figure', {
    speed: 400,
    spaceBetween: 200,
    allowTouchMove: false,
    effect: "fade",
    fadeEffect: {
        crossFade: true
    },
    watchSlidesVisibility: true,
    pagination: {
        el: '.l-headerHome .swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
    autoplay: {
        delay: 5000,
    },
});

// calc content height and set to body
var fullHeight = '0';
setTimeout(function(){fullHeight = $('.l-headerHome').height() + $('.l-footer').height() + $('main').height();}, 1000);
$('body').height(fullHeight);

// scroll link
if ($('.c-linkScroll').length) {
    $('.c-linkScroll').on('click', function() {
        goToSection__scroll($('main'), 50, 50, 700, 10);
    });
}

// sala do aluno
if ($('#salaDoAluno').length) {
    $('.nav__menuHamburguer span').on('click', function() {
        $(this).parent().toggleClass('is-active');
        $('.nav__menu').toggleClass('is-active');
    })
}